<template>
  <v-container fill-height fluid class="justify-center">

      <!-- Sanckbar d'erreur -->
      <v-snackbar v-model="snackErreur" color="red">
        {{snackMessage}}
        <v-btn text color="black" @click.native="snackErreur = false">Close</v-btn>
      </v-snackbar>

      <v-card class="pa-3" style="max-width: 650px" center>
        <v-card-title primary-title>
          Réinitialisation du mot de passe
        </v-card-title>
        <v-card-text>

        <v-form ref="loginForm" v-model="valid" lazy-validation>
          <v-text-field name="password1" v-model="motdepasse1" label="Mot de passe"
            :rules="[rules.required, rules.min]" required autofocus
            :append-icon="show1?'mdi-eye':'mdi-eye-off'" :type="show1 ? 'text' : 'password'"
            @click:append="show1 = !show1">
          </v-text-field>

          <v-text-field name="password2" v-model="motdepasse2" label="Confirmation"
            :rules="[rules.required, rules.min, rules.equal2]" required
            :append-icon="show2?'mdi-eye':'mdi-eye-off'" :type="show2 ? 'text' : 'password'"
            @click:append="show2 = !show2">
          </v-text-field>
        </v-form>

          <div v-if="demandeEnvoyee" style="color: #008000" >Réinitialisation du mot de passe acceptée.</div>
          <div v-if="demandeErreur" style="color: red" >{{ erreur }}</div>
        </v-card-text>
        <v-card-actions>
          <v-btn :disabled="!valid" :loading="loading" color="success" @click="boutonEnvoyerClick">Réinitialiser</v-btn>
        </v-card-actions>
      </v-card>

    </v-container>
</template>

<script>
import axios from 'axios';

export default {

  data() {
    return {
      valid: false,
      loading: false,
      snackErreur: false,
      snackMessage: "",
      motdepasse1: "",
      motdepasse2: "",
      show1: false,
      show2: false,
      demandeEnvoyee: false,
      rules: {
        required: value => !!value || "Required.",
        min: v => (v && v.length >= 6) || "Min 6 characters",
        equal1: v => (v && v == this.motdepasse2) || "Mots de passe différents",
        equal2: v => (v && v == this.motdepasse1) || "Mots de passe différents",
      },
      demandeErreur: false,
      erreur: ""

    }
  },

  watch: {
    $route(to) {
      if (to.url.startsWith('/reinit-password')) {
        this.demandeEnvoyee = false
        this.motdepasse1 = ""
        this.motdepasse2 = ""
      }
    }
  },

  methods: {  
    boutonEnvoyerClick() {
      if ((this.motdepasse1 == "") || (this.motdepasse2 == "") || (this.motdepasse1 != this.motdepasse2)) {
        return; 
      }
      this.demandeEnvoyee = false;
      this.erreur = ""
      this.demandeErreur = false
      this.loading = true
      if (this.courriel != "") {
        const body = {
          newPassword: this.motdepasse1
        }
        const urlParams = new URLSearchParams(window.location.search);
        const access_token = urlParams.get('access_token');
        axios.post("/api/wdUsers/reset-password?access_token=" + access_token, body)
          .then(function() {
            this.demandeEnvoyee = true;
            this.loading = false
            setTimeout(function() {
              this.$router.push("/login")
            }.bind(this), 2000)
          }.bind(this))
          .catch(function(error) {
            this.loading = false
            if ((error.response != undefined) && (error.response.status == 401)) {
              // Erreur "accès refusé", redirection vers la page de connexion
              this.erreur = "Demande refusée, le lien a expiré."
              this.demandeErreur = true
              //this.$router.push('/login?to=/containers')
            }
            else {
              this.erreur = error.message + " : " + error.response.statusText
              this.demandeErreur = true
            }
          }.bind(this));
      }
    }
  }

}
</script>

<style>

</style>